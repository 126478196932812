<template>
  <div class="progress-bar-container">
    <q-linear-progress
      indeterminate
      color="grey"
      class="q-pa-sm"
      v-if="inRequest"
    />
    <div class="progress-bar" v-else>
      <div class="progress" :style="{ width: `${0}%` }" />
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";

// Props
defineProps({
  step: String,
  inRequest: Boolean,
});
</script>

<style scoped>
.progress-bar {
  height: 15px;
  background-color: #e0e0e0;
  overflow: hidden;
  position: relative;
}

.progress {
  height: 100%;
  background-color: #4e4e4e;
  transition: width 0.3s ease;
}

.step-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.step-label {
  font-size: 8pt;
  color: #757575;
  text-align: center;
  width: 100%;
  transition: color 0.3s ease;
}

.step-label.active {
  color: #4e4e4e;
  font-weight: bold;
}

.step-label.completed {
  color: #444444;
}
</style>
