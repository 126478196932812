<template>
  <div
    class="q-pb-md text-center"
    v-html="'O seu endereço será utilizado para eventuais envios de produtos.'"
  />
  <div class="q-pt-sm" v-html="formFields.label" />
  <div class="row q-col-gutter-sm" v-if="!in_request_zip_code">
    <div
      :class="field.class"
      v-for="(field, index) in formFields.fields"
      :key="index + '_salt'"
    >
      <div v-if="field.type !== 'select'">
        <FormInputComponent
          :stepInput="field"
          @inputChange="(value) => handleInputChange(value, index)"
        />
        <span
          class="invalid_zip_code"
          v-html="$t('forms.validation.custom.isCep')"
          v-if="field.name === 'end_cep' && invalid_zip_code"
        />
      </div>
      <div v-if="field.type === 'select'">
        <p class="input-label">{{ field.label }}</p>
        <q-select
          dense
          filled
          clearable
          :options="field.options"
          v-model="field.value"
          :rules="field.rules"
        />
      </div>
    </div>
  </div>
  <div class="row q-col-gutter-sm" v-else>
    <div
      class="q-mt-sm"
      :class="field.class"
      v-for="(field, index) in formFields.fields"
      :key="index + '_salt'"
    >
      <p class="input-label">{{ field.label }}</p>
      <q-skeleton type="rect" class="q-pa-sm q-mb-md" />
    </div>
  </div>
  <FormActions
    :isLastStep="isLastStep"
    :showPrevious="!stepIndex == 0"
    @next-step="onNextStep"
    @go-to-previous-step="onGoToPreviousStep"
  />
</template>

<script setup>
import {
  promisseFields,
  getFormFieldsByStep,
} from "../../form-config/formFields";
import { fillingInPreviousData, formatData } from "../../form-config/helpers";
import { ref, watch, defineProps, onMounted, defineEmits } from "vue";
import FormInputComponent from "../FormInputComponent.vue";
import FormActions from "../FormActions.vue";
import { validateCep } from "validations-br";
import cepPromise from "cep-promise";

const emit = defineEmits(["getPayload", "goToPreviousStep"]);

const props = defineProps({
  partner: {
    type: Object,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  stepIndex: {
    type: Number,
    required: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

const invalid_zip_code = ref(false),
  in_request_zip_code = ref(false),
  formFields = ref(getFormFieldsByStep(props.step));

function handleInputChange(newValue, index) {
  formFields.value.fields[index].value = newValue.value;
}

const onZipCodeChanged = (value) => {
  if (validateCep(value)) {
    in_request_zip_code.value = true;
    // Mapeia a resposta do cepPromise para os campos do formulário
    const setFields = (response) => {
      for (const field of promisseFields) {
        const key = Object.keys(field),
          value = Object.values(field);

        const fieldIndex = formFields.value.fields.findIndex(
          (field) => field.name === value[0]
        );

        if (fieldIndex !== -1)
          handleInputChange({ value: response[key[0]] }, fieldIndex);
      }
    };

    cepPromise(value.replace(/\D/g, ""), {})
      .then(setFields)
      .catch(() => (invalid_zip_code.value = true))
      .finally(() => (in_request_zip_code.value = false));
  }
};

const clearFields = () => {
  in_request_zip_code.value = true;
  setTimeout(() => {
    for (const field of formFields.value.fields) field.value = "";
    in_request_zip_code.value = false;
  }, 20);
};

const onGoToPreviousStep = () => emit("goToPreviousStep");

const onNextStep = () =>
  emit("getPayload", formatData(formFields.value.fields));

onMounted(() => {
  in_request_zip_code.value = true;
  setTimeout(() => {
    formFields.value.fields = fillingInPreviousData(props.partner, props.step);
    in_request_zip_code.value = false;
  }, 20);
});

watch(
  () => formFields.value.fields[0].value, // Observa o campo de CEP
  (newValue) => {
    if (newValue && newValue.length) onZipCodeChanged(newValue);
    if (newValue == null || newValue == "") {
      invalid_zip_code.value = false;
      clearFields();
    }
  }
);
</script>
