import {
  dateRule,
  requiredRule,
  requiredRuleForObjects,
} from "@/shared/helpers/customValidation/index";
import { brUFs, ethnicityOption } from "@/shared/form-presets/data";
import { validateCep } from "validations-br";
import i18n from "@/i18n/wrappers/i18n";

const t = i18n.global.t;

export const partnerTypes = ["pf", "pj"].map((type) => ({
  value: type,
  label: t(`signup.partner_types.${type}`),
}));

export const getAndFormatBankList = () => {
  const bankList = JSON.parse(localStorage.getItem("banksList")) || [];

  return bankList.map((bank) => ({
    value: bank.codigo,
    label: `${bank.codigo} - ${bank.nome}`,
  }));
};

export const formFields = [
  {
    step: "address_step",
    label: "Por favor, digite seu endereço:",
    fields: [
      {
        name: "end_cep",
        value: "",
        type: "text",
        label: t("forms.labels.zip_code"),
        mask: "#####-###",
        caption: undefined,
        placeholder: undefined,
        rules: [
          (val) => requiredRule(val, t("forms.labels.zip_code")),
          (val) => validateCep(val),
        ],
        class: "col-12 col-md-6",
      },
      {
        name: "end_uf",
        value: "",
        type: "select",
        label: t("forms.labels.state"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.state"))],
        options: brUFs().map((uf) => uf.label),
        class: "col-12 col-md-6",
      },
      {
        name: "end_rua",
        value: "",
        type: "text",
        label: t("forms.labels.address"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.address"))],
        class: "col-12",
      },
      {
        name: "end_bairro",
        value: "",
        type: "text",
        label: t("forms.labels.district"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.district"))],
        class: "col-12",
      },
      {
        name: "end_cidade",
        value: "",
        type: "text",
        label: t("forms.labels.city"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.city"))],
        class: "col-12",
      },
    ],
  },
  {
    step: "pf_or_pj_step",
    label: null,
    fields: [
      {
        name: "pf_nascimento",
        value: "",
        type: "date",
        label: t("global.pf_nascimento"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [
          (val) => requiredRule(val, t("global.pf_nascimento")),
          (val) => dateRule(val, t("global.pf_nascimento")),
        ],
        class: "col-12",
      },
      {
        name: "ethnicity_id",
        value: "",
        type: "select",
        label: t("forms.labels.ethnicity"),
        mask: undefined,
        placeholder: undefined,
        caption: t("forms.labels.ethnicity_help"),
        rules: [
          (val) => requiredRuleForObjects(val, t("forms.labels.ethnicity")),
        ],
        options: ethnicityOption(),
        class: "col-12",
      },
    ],
  },
  {
    step: "payment_type_step",
    label: t("account.parameterized_registration.select_pix_or_account"),
    fields: [
      {
        name: "bank_code",
        value: "",
        type: "select",
        label: t("forms.labels.bank"),
        mask: undefined,
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRuleForObjects(val, t("forms.labels.bank"))],
        options: getAndFormatBankList(),
        class: "col-12",
      },
      {
        name: "agency",
        value: "",
        type: "text",
        label: t("forms.labels.agency"),
        mask: "##########",
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.agency"))],
        class: "col-12",
      },
      {
        name: "type",
        value: "",
        type: "select",
        label: t("forms.labels.bank_type"),
        mask: undefined,
        placeholder: undefined,
        rules: [
          (val) => requiredRuleForObjects(val, t("forms.labels.bank_type")),
        ],
        options: [
          { value: 1, label: "Conta Corrente" },
          { value: 2, label: "Conta Poupança" },
        ],
        class: "col-12",
      },
      {
        name: "current_account",
        value: "",
        type: "text",
        label: t("forms.labels.bank_number"),
        mask: "##########",
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.bank_number"))],
        class: "col-12 col-md-8",
      },
      {
        name: "current_account_dv",
        value: "",
        type: "text",
        label: t("forms.labels.bank_number_dv"),
        mask: "#",
        placeholder: undefined,
        caption: undefined,
        rules: [(val) => requiredRule(val, t("forms.labels.bank_number_dv"))],
        class: "col-12 col-md-4",
      },
    ],
  },
];

export const getFormFieldsByStep = (step) => {
  return formFields.find((field) => field.step === step);
};

// Campos do formulário que mapeiam para os valores da resposta do cepPromise
export const promisseFields = [
  { city: "end_cidade" },
  { neighborhood: "end_bairro" },
  { state: "end_uf" },
  { street: "end_rua" },
];
