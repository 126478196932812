<template>
  <div v-if="in_request == false">
    <SendDocumentsComponent
      :isParameterizedForm="true"
      :fetchOrUpdateUser="true"
    />
    <FormActions
      :isLastStep="isLastStep"
      @next-step="onNextStep"
      @go-to-previous-step="onGoToPreviousStep"
      :showNext="
        formatOptions(docTypes, getUserDocuments).length > 0 ? false : true
      "
      :showPrevious="stepIndex !== 0"
    />
  </div>
</template>

<script setup>
import SendDocumentsComponent from "@/components/SendDocuments/SendDocumentsComponent.vue";
import { formatOptions } from "@/components/SendDocuments/setup";
import { useActions, useGetters } from "vuex-composition-helpers";
import { defineEmits, onMounted, ref, defineProps } from "vue";
import FormActions from "../FormActions.vue";

const emit = defineEmits(["goToPreviousStep"]);

defineProps({
  stepIndex: {
    type: Number,
    required: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

const in_request = ref(true);

const { fetchUserDocs, fetchDocsTypes, fetchUserAction } = useActions({
  fetchUserDocs: "account/fetchUserDocs",
  fetchDocsTypes: "account/fetchDocsTypes",
  fetchUserAction: "auth/fetchUserAction",
});

const { getUserDocuments, docTypes } = useGetters({
  getUserDocuments: "account/getUserDocuments",
  docTypes: "account/getDocTypes",
});

const onGoToPreviousStep = () => emit("goToPreviousStep");
const onNextStep = () => fetchUserAction({});

onMounted(() => {
  if (!(getUserDocuments.value.length || docTypes.value.length)) {
    Promise.all([fetchUserDocs(), fetchDocsTypes()]).finally(
      () => (in_request.value = false)
    );
  } else {
    in_request.value = false;
  }
});
</script>
