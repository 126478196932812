<template>
  <template v-if="partner.pf_pj == 'PF'">
    <div class="q-mb-md text-center" v-html="formFields.label" />
    <div class="row q-col-gutter-sm" v-if="!in_request">
      <div
        class="q-mt-sm"
        :class="field.class"
        v-for="(field, index) in formFields.fields"
        :key="index + '_salt'"
      >
        <div v-if="field.type !== 'select'">
          <FormInputComponent
            :stepInput="field"
            @inputChange="(value) => handleInputChange(value, index)"
          />
        </div>
        <div v-if="field.type === 'select'">
          <p class="input-label">{{ field.label }}</p>
          <q-select
            dense
            filled
            clearable
            :options="field.options"
            v-model="field.value"
            :rules="field.rules"
          />
          <p
            class="input-caption q-mt-sm text-justify"
            v-if="field.caption"
            v-html="field.caption"
          />
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="q-mb-md q-pa-md bg-grey-4 default-rounded"><b>CNPJ cadastrado:</b> {{ formatCNPJ(partner.pj_cnpj) }}</div>
    <div class="text-weight-bold">Marque abaixo as atividades (CNAES) de sua empresa</div>
    <div class="text-red text-italic" v-html="$t('forms.tips.cnaes_tip_parameterized')" />
    <div v-if="Object.keys(sign_up_skeleton).length">
      <div class="q-mt-sm q-mb-sm text-italic">Válidos para parceiro MEI</div>
      <q-option-group
        color="grey-8"
        type="checkbox"
        style="font-size: 9pt"
        v-model="cnae_mei"
        :options="
          sign_up_skeleton.cnaes.mei.map((e) => ({
            label: e.description,
            value: e.external_id,
          }))
        "
        @update:model-value="clearCnae('mei')"
      />
    </div>
    <div v-if="Object.keys(sign_up_skeleton).length">
      <div class="q-mt-sm q-mb-sm text-italic">Válidos para parceiro ME</div>
      <q-option-group
        color="grey-8"
        type="checkbox"
        style="font-size: 9pt"
        v-model="cnae_me"
        :options="
          sign_up_skeleton.cnaes.me.map((e) => ({
            label: e.description,
            value: e.external_id,
          }))
        "
        @update:model-value="clearCnae('me')"
      />
    </div>
    <q-input
      style="display: none"
      :model-value="validateValue"
      :rules="[(val) => !!val || '']"
    />
    <div class="bg-red-2 q-mt-md q-ma-sm q-pa-md" v-show="needsCnae">
      Selecione pelo menos uma atividade.
    </div>
  </template>
  <FormActions
    :isLastStep="isLastStep"
    @next-step="onNextStep"
    :showPrevious="!stepIndex == 0"
    @go-to-previous-step="onGoToPreviousStep"
  />
</template>

<script setup>
import { formatCNPJ } from "@/shared/helpers/stringManipulations";
import { getFormFieldsByStep } from "../../form-config/formFields";
import FormActions from "../FormActions.vue";
import { useGetters } from "vuex-composition-helpers";
import FormInputComponent from "../FormInputComponent.vue";
import { ref, defineProps, onMounted, defineEmits } from "vue";
import { fillingInPreviousData, formatData } from "../../form-config/helpers";

const emit = defineEmits(["getPayload", "goToPreviousStep"]);

const props = defineProps({
  partner: {
    type: Object,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  stepIndex: {
    type: Number,
    required: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

const cnae_mei = ref([]),
  cnae_me = ref([]);

const in_request = ref(false);
const needsCnae = ref(false);
const validateValue = ref(null);
const formFields = ref(getFormFieldsByStep(props.step) || {});

const { sign_up_skeleton } = useGetters({
  sign_up_skeleton: "auth/getSignUpSkeleton",
});

const clearCnae = (type) => {
  if (type === "mei") cnae_me.value = [];
  else cnae_mei.value = [];
};

function handleInputChange(newValue, index) {
  formFields.value.fields[index].value = newValue.value;
}

const onGoToPreviousStep = () => emit("goToPreviousStep");

const onNextStep = () => {
  if (
    props.partner.pf_pj == "PJ" &&
    cnae_mei.value.length == 0 &&
    cnae_me.value.length == 0
  ) {
    needsCnae.value = true;
    return;
  } else {
    needsCnae.value = false;
    validateValue.value = "validated";
    emit(
      "getPayload",
      props.partner.pf_pj == "PJ"
        ? { pj_cnaes: cnae_mei.value.length ? cnae_mei.value : cnae_me.value }
        : formatData(formFields.value.fields)
    );
  }
};

onMounted(() => {
  in_request.value = true;

  setTimeout(() => {
    formFields.value.fields = fillingInPreviousData(props.partner, props.step);

    let pjCnaes = [];

    // Verifica se pj_cnaes é uma string JSON e faz o parse, ou usa diretamente se já for um array/objeto
    if (typeof props.partner.pj_cnaes === "string") {
      try {
        pjCnaes = JSON.parse(props.partner.pj_cnaes);
      } catch (e) {
        console.error("Erro ao fazer parse de pj_cnaes:", e);
      }
    } else if (Array.isArray(props.partner.pj_cnaes)) {
      pjCnaes = props.partner.pj_cnaes;
    }

    // Se pjCnaes for um array válido, processa os CNAEs
    if (pjCnaes.length > 0) {
      pjCnaes.forEach((cnae) => {
        // Verifica se o cnae pertence à categoria MEI
        if (
          sign_up_skeleton.value.cnaes.mei.some((e) => e.external_id === cnae)
        ) {
          cnae_mei.value.push(cnae);
        }
        // Verifica se o cnae pertence à categoria ME
        else if (
          sign_up_skeleton.value.cnaes.me.some((e) => e.external_id === cnae)
        ) {
          cnae_me.value.push(cnae);
        }
      });
    }

    in_request.value = false;
  }, 200);
});
</script>
