<template>
  <div class="q-mb-md text-justify" v-html="formFields.label" />
  <div class="q-gutter-sm">
    <q-radio v-model="shape" val="pix_account" label="Pix" color="black" />
    <q-radio v-model="shape" val="bank_account" label="Conta" color="black" />
  </div>
  <div class="row q-col-gutter-sm" v-if="!in_request">
    <template v-if="shape === 'bank_account'">
      <div
        class="q-mt-sm"
        :class="field.class"
        v-for="(field, index) in formFields.fields"
        :key="index + '_salt'"
      >
        <div v-if="field.type !== 'select'">
          <FormInputComponent
            :stepInput="field"
            @inputChange="(value) => handleInputChange(value, index)"
          />
        </div>
        <div v-if="field.type === 'select'">
          <p class="input-label">{{ field.label }}</p>
          <q-select
            dense
            filled
            clearable
            :options="field.options"
            v-model="field.value"
            :rules="field.rules"
          />
          <p
            class="input-caption q-mt-sm text-justify"
            v-if="field.caption"
            v-html="field.caption"
          />
        </div>
      </div>
    </template>
    <template v-else>
      <div
        class="q-mt-sm"
        :class="field.class"
        v-for="(field, index) in pixFields"
        :key="index + '_salt'"
      >
        <div v-if="field.type === 'select'">
          <p class="input-label">{{ field.label }}</p>
          <q-select
            dense
            filled
            clearable
            :options="field.options"
            v-model="pixType"
            :rules="field.rules"
            lazy-rules="ondemand"
          />
        </div>
        <div v-if="field.type !== 'select'">
          <p class="input-label">{{ field.label }}</p>
          <q-input
            dense
            filled
            clearable
            lazy-rules="ondemand"
            v-model="pixValue"
            :rules="[
              ...field.rules,
              (v) => validatePix({ type: pixType.type, content: v }),
            ]"
            :mask="getPixMask(pixType ? pixType.value : '')"
            :type="field.type"
          />
        </div>
      </div>
    </template>
  </div>
  <FormActions
    :isLastStep="isLastStep"
    :showPrevious="stepIndex !== 0"
    @next-step="onNextStep"
    @go-to-previous-step="onGoToPreviousStep"
  />
</template>

<script setup>
import {
  requiredRule,
  requiredRuleForObjects,
} from "@/shared/helpers/customValidation/index";
import { getFormFieldsByStep } from "../../form-config/formFields";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { getPartnerTypes } from "../../../children/setup";
import FormInputComponent from "../FormInputComponent.vue";
import { ref, defineProps, onMounted, watch, defineEmits } from "vue";
import { useI18n } from "vue-i18n";
import FormActions from "../FormActions.vue";
import {
  fillingInPreviousData,
  formatData,
  getPixMask,
} from "../../form-config/helpers";

const emit = defineEmits(["getPayload", "goToPreviousStep"]);

const accountNamespace = createNamespacedHelpers("account");

const { t } = useI18n();

const props = defineProps({
  partner: {
    type: Object,
    required: true,
  },
  step: {
    type: String,
    required: true,
  },
  stepIndex: {
    type: Number,
    required: true,
  },
  isLastStep: {
    type: Boolean,
    required: true,
  },
});

const pixType = ref(null);
const pixValue = ref(null);
const in_request = ref(false);
const shape = ref("pix_account");
const formFields = ref(getFormFieldsByStep(props.step) || {});

const { validatePix } = accountNamespace.useActions({
  validatePix: "validatePix",
});

function handleInputChange(newValue, index) {
  formFields.value.fields[index].value = newValue.value;
}

const pixFields = ref([
  {
    name: "type",
    type: "select",
    label: t("forms.labels.pix_types"),
    options: getPartnerTypes(props.partner.pf_pj),
    rules: [(val) => requiredRuleForObjects(val, t("forms.labels.pix_types"))],
    class: "col-12",
  },
  {
    name: "content",
    type: "text",
    label: t("forms.labels.pix_key"),
    rules: [(val) => requiredRule(val, t("forms.labels.pix_key"))],
    class: "col-12",
  },
]);

const onGoToPreviousStep = () => emit("goToPreviousStep");

const onNextStep = () => {
  const payload = {
    pix_account:
      shape.value === "pix_account"
        ? {
            type: pixType.value.value,
            content: pixValue.value,
          }
        : null,
    bank_account:
      shape.value === "bank_account"
        ? formatData(formFields.value.fields)
        : null,
  };

  emit("getPayload", payload);
};

onMounted(() => {
  in_request.value = true;
  if (props.partner.pix_account) {
    shape.value = "pix_account";

    pixType.value = getPartnerTypes(props.partner.pf_pj).find(
      (type) => type.value === props.partner.pix_account.type
    );
    setTimeout(() => (pixValue.value = props.partner.pix_account.content), 200);
  } else if (props.partner.bank_account) {
    shape.value = "bank_account";
    formFields.value.fields = fillingInPreviousData(
      props.partner.bank_account,
      props.step
    );
  } else shape.value = "pix_account";

  in_request.value = false;
});

watch(pixType, () => (pixValue.value = ""));
</script>
