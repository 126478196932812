import script from "./AddressStep.vue?vue&type=script&setup=true&lang=js"
export * from "./AddressStep.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QSelect from 'quasar/src/components/select/QSelect.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import qInstall from "../../../../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QSelect,QSkeleton});
