<template>
  <div>
    <div class="blur-container">
      <q-skeleton height="250px" animation="none" />
      <div class="row q-col-gutter-lg q-mt-md">
        <div class="col-12 col-md-3" v-for="i in 4" :key="i">
          <q-skeleton height="550px" animation="none" />
        </div>
        <div class="col-12 col-md-4" v-for="i in 3" :key="i">
          <q-skeleton height="550px" animation="none" />
        </div>
      </div>
    </div>
    <FormModal />
  </div>
</template>

<script setup>
import FormModal from "./parameterized-registration/components/FormModal.vue";
import helpers from "@/shared/helpers";
const { getCustomLayout } = helpers.customLayout;

import { onMounted, ref } from "vue";

const layoutConfig = ref(null);

onMounted(() => {
  layoutConfig.value = getCustomLayout();
});
</script>

<style lang="scss" scoped>
.blur-container {
  filter: blur(9px);
}
</style>
