<template>
  <q-dialog
    persistent
    v-model="persistent"
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card style="width: 490px" class="dialog-form">
      <ProgressBar :step="step" :inRequest="in_request" />
      <div class="text-center q-mt-md">
        <img
          :src="parent.logo_foto_link"
          :alt="parent.nome"
          style="width: 120px"
        />
      </div>
      <!-- Step Progress Bar -->
      <q-card-section
        v-if="Object.keys(formSteps).length"
        style="margin-top: -20px"
      >
        <div
          class="text-h5 text-weight-bold text-center q-pa-md"
          v-html="$t('signup.parameterized_registration.not_long')"
        />
        <hr />
      </q-card-section>

      <!-- Step Content -->
      <q-card-section>
        <q-form @submit="onSubmit()" greedy>
          <div class="q-pr-sm q-pl-sm">
            <AddressStep
              :step="step"
              :partner="partner"
              :stepIndex="formSteps.indexOf(step)"
              :isLastStep="isLastStep(step)"
              @getPayload="getPayload"
              @goToPreviousStep="goToPreviousStep"
              v-if="step === 'address_step'"
            />
            <PFOrPJStep
              :step="step"
              :partner="partner"
              :stepIndex="formSteps.indexOf(step)"
              :isLastStep="isLastStep(step)"
              @getPayload="getPayload"
              @goToPreviousStep="goToPreviousStep"
              v-if="step === 'pf_or_pj_step'"
            />
            <PaymentType
              :step="step"
              :partner="partner"
              :stepIndex="formSteps.indexOf(step)"
              :isLastStep="isLastStep(step)"
              @getPayload="getPayload"
              @goToPreviousStep="goToPreviousStep"
              v-if="step === 'payment_type_step'"
            />
            <DocumentStep
              :step="step"
              :partner="partner"
              :stepIndex="formSteps.indexOf(step)"
              :isLastStep="isLastStep(step)"
              @getPayload="getPayload"
              @goToPreviousStep="goToPreviousStep"
              v-if="step === 'document_step'"
            />
            <!-- Customizable Steps -->
            <template
              v-for="(stepData, stepKey) in customizableSteps"
              :key="stepKey"
            >
              <CustomizableStep
                v-if="step === stepKey"
                :step="step"
                :partner="partner"
                :headContent="stepData.head_content"
                :fields="stepData.fields"
                :stepIndex="formSteps.indexOf(step)"
                :isLastStep="isLastStep(step)"
                @getPayload="getPayload"
                @goToPreviousStep="goToPreviousStep"
              />
            </template>
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script setup>
import { useGetters, useActions } from "vuex-composition-helpers";
import { onMounted, ref } from "vue";

import PFOrPJStep from "./steps/PFOrPJStep.vue";
import AddressStep from "./steps/AddressStep.vue";
import PaymentType from "./steps/PaymentType.vue";
import DocumentStep from "./steps/DocumentStep.vue";
import CustomizableStep from "./steps/CustomizableStep.vue";
import ProgressBar from "./ProgressBar.vue";

// Component state and references
const in_request = ref(false);
const persistent = ref(true);
const payload = ref({});
const step = ref("");

const {
    partner,
    parent,
    formSteps,
    customizableSteps,
    getUserDocuments,
    docTypes,
  } = useGetters({
    partner: "account/getPartnerAccount",
    parent: "partner/getPartner",
    formSteps: "account/getParameterizedRegistrationSteps",
    customizableSteps: "account/getCustomizableSteps",
    getUserDocuments: "account/getUserDocuments",
    docTypes: "account/getDocTypes",
  }),
  {
    fetchUserAction,
    fetchBanksListAction,
    updateUserPartnerAction,
    fetchSignUpSkeletonAction,
    fetchParameterizedRegistrationParam,
    fetchUserDocs,
    fetchDocsTypes,
  } = useActions({
    fetchUserAction: "auth/fetchUserAction",
    fetchBanksListAction: "account/fetchBanksListAction",
    updateUserPartnerAction: "account/updateUserPartnerAction",
    fetchSignUpSkeletonAction: "auth/fetchSignUpSkeletonAction",
    fetchParameterizedRegistrationParam:
      "account/fetchParameterizedRegistrationParam",
    fetchUserDocs: "account/fetchUserDocs",
    fetchDocsTypes: "account/fetchDocsTypes",
  });

// Set payload data from form
const getPayload = (data) => (payload.value = data);

// Function to check if the current step is the last one
const isLastStep = (currentStep) => {
  return formSteps.value.indexOf(currentStep) === formSteps.value.length - 1;
};

// Navigate steps
const goToNextStep = () =>
  (step.value = formSteps.value[formSteps.value.indexOf(step.value) + 1]);
const goToPreviousStep = () =>
  (step.value = formSteps.value[formSteps.value.indexOf(step.value) - 1]);

const isNextStepDocumentStep = () => {
  const currentStepIndex = formSteps.value.indexOf(step.value);
  return (
    currentStepIndex !== -1 &&
    formSteps.value[currentStepIndex + 1] === "document_step"
  );
};

// Submit form and go to the next step
const onSubmit = () => {
  if (step.value === "document_step") return;

  in_request.value = true;
  updateUserPartnerAction(payload.value).then(() => {
    if (isLastStep(step.value)) {
      fetchUserAction();
    } else if (isNextStepDocumentStep()) {
      fetchUserDocs()
        .then(fetchDocsTypes)
        .then(() => {
          if (getUserDocuments.value.length || docTypes.value.length) {
            goToNextStep();
            in_request.value = false;
          } else {
            fetchUserAction().finally(() => {
              in_request.value = false;
            });
          }
        });
    } else {
      goToNextStep();
      in_request.value = false;
    }
  });
};

// Lifecycle hooks
onMounted(() => {
  Promise.all([
    fetchParameterizedRegistrationParam({
      slug: parent.value.slug,
      param: "parameterized_registration",
    }),
    fetchSignUpSkeletonAction({ slug: parent.value.slug }),
    fetchBanksListAction(),
  ]).then(() => {
    step.value = formSteps.value[0];
  });
});
</script>

<style lang="scss">
.input-label {
  font-size: 13px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 2px;
  color: #111928;
  text-transform: uppercase;
}
</style>
